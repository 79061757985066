<template>
  <component :is="cliente === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="cliente === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <b-tabs
      v-if="cliente"
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Ficha</span>
        </template>
        <cliente-edit-tab-account
          :cliente="cliente"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Information -->
      <!-- <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Information</span>
        </template>
        <user-edit-tab-information class="mt-2 pt-75" />
      </b-tab> -->

      <!-- Tab: Social -->
      <!-- <b-tab>
        <template #title>
          <feather-icon
            icon="Share2Icon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Social</span>
        </template>
        <user-edit-tab-social class="mt-2 pt-75" />
      </b-tab> -->
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import useClientes from '@/views/bimbo/clientes/useClientes'
import { onMounted } from '@vue/composition-api'
import ClienteEditTabAccount from './ClienteEditTabAccount.vue'
// import UserEditTabInformation from './UserEditTabInformation.vue'
// import UserEditTabSocial from './UserEditTabSocial.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    ClienteEditTabAccount,
    // UserEditTabInformation,
    // UserEditTabSocial,
  },
  data() {
    return {
      // userData: null,
    }
  },
  setup() {
    const {
      clientes,
      cliente,
      userData,
      fetchClientes,
      notifica,
    } = useClientes()

    onMounted(fetchClientes)

    return {
      clientes,
      cliente,
      userData,
      notifica,
    }
  },
}
</script>

<style>

</style>
