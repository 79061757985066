import store from '@/store'
import { ref, computed } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useClientes() {
  // Use toast
  const toast = useToast()
  // Use route
  const { route } = useRouter()
  const clientes = computed(() => store.state.clientes.clientes)

  const fetchClientes = () => {
    store.dispatch('clientes/getClientes')
  }

  const clienteId = computed(() => route.value.params.id)
  const cliente = computed(() => {
    if (!clienteId.value) return {}
    return clientes.value.filter(c => c.id === clienteId.value)[0]
  })

  const notifica = (title, icon, variant, position = 'top-center') => {
    toast({
      component: ToastificationContent,
      props: {
        title,
        icon, // 'AlertTriangleIcon',
        variant, // 'danger',
      },
    },
    {
      position, // 'top-center',
    })
  }

  const blankUserData = {
    id: '',
    nombre: '',
    lat: '',
    lng: '',
    dir_literal: '',
    dir_calle: '',
    dir_numero: '',
    dir_cp: '',
    dir_localidad: '',
    place_id: '',
    hub: '',
    ruta: '',
    hora_ini: 28800,
    hora_fin: 64800,
    hora_tarde_ini: 0,
    hora_tarde_fin: 0,
    email: '',
    tlf: '',
    notas: '',
  }

  const userData = ref(JSON.parse(JSON.stringify(blankUserData)))

  const setClient = data => {
    store.dispatch('clientes/addCliente', data)
      .then(() => {
        notifica('Datos guardados con éxito', 'CheckIcon', 'success')
      })
  }

  return {
    clientes,
    cliente,
    userData,
    fetchClientes,
    notifica,
    setClient,
  }
}
