<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    :width="size"
    :height="size"
  >
    <circle
      :fill="colors.interior"
      cx="12"
      cy="12"
      r="12"
    />
    <path
      :fill="colors.exterior"
      d="M 12,0 C 5.4,0 0,5.4 0,12 0,18.6 5.4,24 12,24 18.6,24 24,18.6 24,12 24,5.4 18.6,0 12,0 Z m 0,20 C 7.6,20 4,16.4 4,12 4,7.6 7.6,4 12,4 c 4.4,0 8,3.6 8,8 0,4.4 -3.6,8 -8,8 z"
    />
    <text
      v-if="num >= 0"
      :fill="colors.numero"
      style="font-weight:bold;font-size:14px;text-align:center;text-anchor:middle;"
      x="11.5"
      y="17"
    >
      <tspan>
        {{ num }}
      </tspan>
    </text>
    <!-- <circle
      :fill="color"
      cx="12"
      cy="12"
      r="6"
    /> -->
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#000',
    },
    size: {
      type: String,
      default: '24',
    },
    num: {
      type: Number,
      default: null,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    colors() {
      const result = {
        exterior: this.color,
        interior: '#ffffff',
        numero: this.color,
      }
      if (this.isSelected) {
        result.numero = result.interior
        result.interior = result.exterior
      }
      return result
    },
  },
}
</script>

<style lang="css" scoped>
  svg {
    cursor: pointer;
  }
</style>
