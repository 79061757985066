<template>
  <b-form-group
    :label="label"
    :label-for="id"
  >
    <b-form-timepicker
      :id="id"
      v-model="hora"
      v-bind="labels[locale] || {}"
      :locale="locale"
      @context="onContextHora"
      @input="onImput"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormTimepicker,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormTimepicker,
  },
  props: {
    time: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      hora: null,
      locale: 'es-ES',
      labels: {
        es: {
          labelHours: 'Hora',
          labelMinutes: 'Minuto',
          labelSeconds: 'Segundos',
          labelIncrement: 'Más',
          labelDecrement: 'Menos',
          labelSelected: 'Seleeciona',
          labelNoTimeSelected: 'Nada seleccionado',
          labelCloseButton: 'Cerrar',
        },
      },
      horaContext: null,
    }
  },

  watch: {
    time(newValue) {
      if (newValue) {
        this.hora = new Date(newValue * 1000).toISOString().substr(11, 8)
      }
    },
  },

  methods: {
    onContextHora(ctx) {
      this.horaContext = ctx
    },
    onImput() {
      const valor = this.horaContext.hours * 3600 + this.horaContext.minutes * 60
      const payload = {
        id: this.id,
        valor,
      }
      this.$emit('setHora', payload)
    },
  },

}
</script>

<style>

</style>
