<template>
  <b-card no-body>
    <l-map
      :zoom="zoom"
      :center="center"
    >
      <l-tile-layer :url="url" />
      <l-circle
        v-if="false"
        :lat-lng="circle.center"
        :radius="circle.radius"
        :color="circle.color"
      />
      <l-marker
        :lat-lng="markerLatLng"
        :icon="iconHome"
      >
        <l-popup>CargoBici</l-popup>
      </l-marker>

      <l-marker
        v-for="(marker, i) in markersCalc"
        :key="i"
        :lat-lng="[marker.lat, marker.lng, { draggable: 'true' }]"
        @click="selectedMarker(marker)"
      >
        <l-popup>{{ marker.orden }} - {{ marker.nombre }}</l-popup>
        <l-icon
          :icon-anchor="staticAnchor"
          :icon-size="staticSize"
          class-name="someExtraClass"
        >
          <!-- <div :class="`headline txt-ruta-${marker.ruta}`">
            {{ marker.orden }}
          </div> -->
          <!-- <img :src="require('@/assets/images/icons/maps/marker_yellow.svg')"> -->
          <marker-icon
            :color="colorIcons[marker.ruta]"
            :num="marker.orden"
            :is-selected="marker.isSelected"
            class="mark-icon"
          />
        </l-icon>
      </l-marker>
      <l-polyline
        v-if="ruta"
        :lat-lngs="ruta.latlngs"
        :color="ruta.color"
        line-cap="<path d='M0,0 V4 L2,2 Z' fill='red' />"
        :opacity="0.7"
      >
        <l-popup content="polyline" />
      </l-polyline>
    </l-map>
  </b-card>
</template>

<script>
/* eslint-disable global-require */
import { BCard } from 'bootstrap-vue'
import {
  LMap, LTileLayer, LCircle, LMarker, LPopup, LPolyline, LIcon,
} from 'vue2-leaflet'
import { Icon, icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'
// import gmap from '@/@fake-db/data/mock_google.json'
import MarkerIcon from '@/assets/images/icons/maps/MarkerIcon.vue'
// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
/* eslint-disable global-require */

export default {
  components: {
    LMap,
    LTileLayer,
    LCircle,
    LMarker,
    LPopup,
    LPolyline,
    LIcon,
    BCard,
    MarkerIcon,
  },
  props: {
    markers: {
      type: Array,
      required: true,
    },
    path: {
      type: Array,
      required: true,
    },
    pre: {
      type: Boolean,
      required: false,
      default: true,
    },
    markersSelectByPicking: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      // gmapGoogle: gmap,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 14,
      center: [41.4169, 2.20858],
      markerLatLng: [41.4169, 2.20858, { draggable: 'true' }],
      circle: {
        center: [41.4168037, 2.2066069],
        radius: 1000,
        color: '#EA5455',
      },
      iconHome: icon({
        iconUrl: require('@/assets/images/icons/maps/marker_home.svg'),
        iconSize: [24, 24],
        iconAnchor: [12, 12],
      }),
      iconDefault: icon({
        iconUrl: require('@/assets/images/icons/maps/marker_yellow.svg'),
        iconSize: [24, 24],
        iconAnchor: [12, 12],
      }),
      staticAnchor: [12, 12],
      staticSize: [24, 24],
      colorIcons: ['#02ffb2', '#fa0303', '#2196f3', '#a202ff'],
      colorRuta: ['green', 'red', 'blue', 'purple'],
      markersSelects: [],
    }
  },
  computed: {
    lc() {
      return `<path
        d='M0,0 V4 L2,2 Z' 
        fill='red' 
        />`
    },
    markersCalc() {
      const result = this.markers
      for (let index = 0; index < result.length; index += 1) {
        const marker = result[index]
        if (this.markersSelects.includes(marker.id)) result[index].isSelected = true
        else result[index].isSelected = false
      }
      return result
    },
    ruta() {
      if (!this.path.length) return false
      const pathObj = this.path // gmap.overview_path
      const pathArr = Object.keys(pathObj).map(k => [pathObj[k].lat, pathObj[k].lng])
      const polyline = {
        type: 'Ruta 1',
        latlngs: pathArr,
        color: 'red',
      }
      return polyline // gmap.overview_path
    },
  },
  watch: {
    ruta() {
      this.$emit('newRutaCalculed')
      this.markersSelects = []
    },
    markersSelectByPicking(newValue) {
      this.markersSelects = newValue
    },
  },
  methods: {
    selectedMarker(marker) {
      const index = this.markersSelects.indexOf(marker.id)
      if (index > -1) this.markersSelects.splice(index, 1)
      else this.markersSelects.push(marker.id)
      this.$emit('selectedByMarker', marker.id)
    },
  },
}
</script>

<style lang="scss">
.mk-icon-cli {
  fill: red;
}
.vue2leaflet-map {
  &.leaflet-container{
    height: 80vh;
  }
}
// .someExtraClass {
//   background-color: aqua;
//   padding: 10px;
//   border: 1px solid #333;
//   border-radius: 0 20px 20px 20px;
//   box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.2);
//   text-align: center;
//   width: auto !important;
//   height: auto !important;
//   margin: 0 !important;
// }
.someExtraClass {
  background-color: transparent;
  padding: 0;
  border: none;
  box-shadow: none;
  text-align: center;
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
  padding: 0;
}

.headline {
  font-size: 1.2em;
  font-weight: 800;
  color: #090909;
  transform: translateY(1.56em);
}
.txt-ruta-0 {
  color: #02ffb2;
}
.txt-ruta-1 {
  color: #fa0303;
}
.txt-ruta-2 {
  color: #2196f3;
}
.txt-ruta-3 {
  color: #a202ff;
}
.mark-icon {
    position: absolute;
    top: -12px;
    left: -12px;
}
</style>
