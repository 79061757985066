<template>
  <div>
    <template v-if="true">
      <h1 v-if="clienteData && clienteData.nombre">
        {{ clienteData.nombre }}
      </h1>

      <!-- User Info: Input Fields -->
      <b-form>
        <b-row>

          <!-- Field: Nombre -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Nombre"
              label-for="nombre"
            >
              <b-form-input
                id="nombre"
                v-model="clienteData.nombre"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Dirección: Literal -->
          <b-col
            cols="12"
            md="8"
          >
            <b-form-group
              label="Dirección: Literal"
              label-for="dir-literal"
            >
              <b-form-input
                id="dir-literal"
                v-model="clienteData.dir_literal"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Dirección: Calle -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Dirección: Calle"
              label-for="dir-calle"
            >
              <b-form-input
                id="dir-calle"
                v-model="clienteData.dir_calle"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Dirección: Número -->
          <b-col
            cols="12"
            md="1"
          >
            <b-form-group
              label="Número"
              label-for="dir-numero"
            >
              <b-form-input
                id="dir-numero"
                v-model="clienteData.dir_numero"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Dirección: Localidad -->
          <b-col
            cols="12"
            md="2"
          >
            <b-form-group
              label="Localidad"
              label-for="dir-localidad"
            >
              <b-form-input
                id="dir-localidad"
                v-model="clienteData.dir_localidad"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Dirección: CP -->
          <b-col
            cols="12"
            md="1"
          >
            <b-form-group
              label="CP"
              label-for="dir-cp"
            >
              <b-form-input
                id="dir-cp"
                v-model="clienteData.dir_cp"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Dirección: Lat -->
          <b-col
            cols="12"
            md="2"
          >
            <b-form-group
              label="Lat"
              label-for="lat"
            >
              <b-form-input
                id="lat"
                v-model="clienteData.lat"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Dirección: Lng -->
          <b-col
            cols="12"
            md="2"
          >
            <b-form-group
              label="Lng"
              label-for="lng"
            >
              <b-form-input
                id="lng"
                v-model="clienteData.lng"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Ruta -->
          <b-col
            cols="12"
            md="2"
          >
            <b-form-group
              label="Ruta"
              label-for="ruta"
            >
              <b-form-input
                id="ruta"
                v-model="clienteData.ruta"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Hub -->
          <b-col
            cols="12"
            md="2"
          >
            <b-form-group
              label="Hub"
              label-for="hub"
            >
              <b-form-input
                id="hub"
                v-model="clienteData.hub"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Horaio -->
          <!-- <b-col
            cols="12"
            md="2"
          >
            <b-form-group
              label="Desde"
              label-for="hini"
            >
              <b-form-timepicker
                id="hini"
                v-model="hIni"
                v-bind="labels[locale] || {}"
                :locale="locale"
                @context="onContextHora"
                @input="onImput('hora_ini')"
              />
            </b-form-group>
          </b-col> -->

          <!-- Field: Horaio -->
          <b-col
            cols="12"
            md="2"
          >
            <time-picker-component
              :id="'hora_ini'"
              :label="'Mañanas Desde'"
              :time="clienteData.hora_ini || 0"
              @setHora="setHora"
            />
          </b-col>

          <b-col
            cols="12"
            md="2"
          >
            <time-picker-component
              :id="'hora_fin'"
              :label="'Mañanas Hasta'"
              :time="clienteData.hora_fin || 0"
              @setHora="setHora"
            />
          </b-col>

          <b-col
            cols="12"
            md="2"
          >
            <time-picker-component
              :id="'hora_tarde_ini'"
              :label="'Tardes Desde'"
              :time="clienteData.hora_tarde_ini || 0"
              @setHora="setHora"
            />
          </b-col>

          <b-col
            cols="12"
            md="2"
          >
            <time-picker-component
              :id="'hora_tarde_fin'"
              :label="'Tardes Hasta'"
              :time="clienteData.hora_tarde_fin || 0"
              @setHora="setHora"
            />
          </b-col>

          <!-- Field: Email -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="clienteData.email"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Tlf -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Tlf"
              label-for="tlf"
            >
              <b-form-input
                id="tlf"
                v-model="clienteData.tlf"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Notas -->
          <b-col
            cols="12"
          >
            <b-form-group
              label="Notas"
              label-for="notas"
            >
              <b-form-textarea
                id="notas"
                v-model="clienteData.notas"
                placeholder="Notas"
                rows="3"
              />
            </b-form-group>
          </b-col>

        </b-row>
      </b-form>

      <!-- Action Buttons -->
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="setClient(clienteData)"
      >
        Guardar
      </b-button>
      <b-button
        variant="outline-secondary"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="$router.go(-1)"
      >
        Cerrar
      </b-button>

      <div class="mt-3">
        <mapa-picking
          v-if="clienteData"
          :markers="[{ lat: clienteData.lat, lng: clienteData.lng, orden: null, ruta: clienteData.ruta, nombre: clienteData.nombre }]"
          :path="[]"
          :pre="true"
        />
      </div>
    </template>
    <template v-else>
      <h1>Prueba {{ clienteData.nombre }}</h1>
      <pre>{{ clienteData }}</pre>
    </template>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BFormTextarea,
  // BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import { ref, onMounted } from '@vue/composition-api'
import useClientes from '@/views/bimbo/clientes/useClientes'
import MapaPicking from '@/views/bimbo/components/MapaPicking.vue'
import TimePickerComponent from '@/views/bimbo/components/TimePickerComponent.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    // BTable,
    // BCard,
    // BCardHeader,
    // BCardTitle,
    // BFormCheckbox,
    BFormTextarea,
    // BFormTimepicker,
    // vSelect,

    MapaPicking,
    TimePickerComponent,
  },
  props: {
    cliente: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      setClient,
    } = useClientes()

    const clienteData = ref({})
    // const lastHoraChanged = ref({})

    const setCliente = () => {
      if (props.cliente) {
        clienteData.value = JSON.parse(JSON.stringify(props.cliente))
      }
    }

    onMounted(setCliente)

    // const onContextHora = ctx => {
    //   lastHoraChanged.value = ctx
    // }

    // const onImput = h => {
    //   const valor = lastHoraChanged.value.hours * 3600 + lastHoraChanged.value.minutes * 60
    //   clienteData.value[h] = valor
    // }

    const setHora = data => {
      const { id, valor } = data
      clienteData.value[id] = valor
    }

    return {
      setClient,
      clienteData,
      setHora,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
